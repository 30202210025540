@import "./color";

@include loop(0, 100, 1, ".b-", "", "border", "px", "!important");

.float-r{
    float: right;
}

.float-l{
    float: left;
}

.m-auto{
	margin: auto;
}

.form-radio.is-invalid ~ .invalid-feedback{
    display: block;
}

// Searchable Dropdown

.dropdown-content {
    position: relative;

    .dropdown-container{
        overflow: auto;
        background-color: #f6f6f6;
        border: 1px solid #ddd;
        position: absolute;
        z-index: 1;
        width: 100%;
        max-height: 250px;
        min-height: 45px;

        .content {
            text-decoration: none;
            background: #fff;
            border-bottom: solid 1px #ccc;
            min-height: 50px;
            cursor: pointer;
            padding: 13px 20px;
        }
        .content:hover {
            text-decoration: underline;
        }

        .content.lastchild {
            background: #e5eeff;
            min-height: 45px;
        }
    }
}
.dropdown-content .content:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

.review-event-banner{
    height: 150px;
    background-color: #eee;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.review-event-letterhead{
    height: 48px;
    background-color: #eee;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.review-event-florplan{
    height: 100px;
    background-color: #eee;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.event-profile-card{

    .event-logo-container{
        position: absolute;
        box-shadow: 0px 0px 10px rgba(27, 27, 27, 0.2);
        bottom: -25px;
        z-index: 1;
        background-color: #f1f1f1;

        .event-logo{
            border: 2px solid #fff;
            width: 100px;
            height: 100px;
            display: block;
            border-radius: 4px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .card-event-header{
        background-image: url('/assets/images/event_cover.png');
        color: #fff;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    .card-event-header.archived{
        background-image: url('/assets/images/archive_event_cover.png') !important;
    }

    .card-event-header.past{
        background-image: url('/assets/images/past_event_cover.png') !important;
    }

    .event-status-ribbon {
        display: flex;
        padding: 6px 12px;
        font-size: 16px;
        color: #fff;
        font-weight: 400;
        position: absolute;
        right: -11px;
        top: 12px;
        .status-indicator{
            position: relative;
            padding-left: 23px;
            font-size: 14px;
            .status-icon:before{
                position: absolute;
                display: inline-block;
                width: 13px;
                height: 13px;
                left: 0;
                top: 1px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                font-family: 'Font Awesome 5 Free';
                font-size: 12px;
                font-weight: 900;
            }
        }
        &:before{
            content: '';
            position: absolute;
            bottom: -7px;
            right: 0px;
            border-bottom: 7px solid transparent;
            border-left: 11px solid #10433c;
        }
        &.unpublished{
            background-image: $unpublish;
            .status-icon:before{
                content: '\f1ce';
            }
            &:before{
                border-left-color: #7e3706;
            }
        }
        &.ongoing{
            background-image: $ongoing;
            &:before{
                border-left-color: #10433c;
            }
            .status-icon:before{
                content: '\f2f1';
            }
        }
        &.published{
            background-image: $published;
            &:before{
                border-left-color: #1c500b;
            }
            .status-icon:before{
                content: '\f058';
            }
        }
        &.past{
            background-image: $archive;
            &:before{
                border-left-color: #3b3b3b;
            }
            .status-icon:before{
                content: '\f073';
            }
        }
        &.archived{
            background-image: $archive;
            &:before{
                border-left-color: #3b3b3b;
            }
            .status-icon:before{
                content: '\f073';
            }
        }
    }

    .event-address-map{
        width: 100%;
        height: 200px;
        background-color: #ccc;

        agm-map{
            height: 200px;
        }
    }
}

.event-status-ribbon.delegate-list-ribbon {
    right: 4px !important;
}

// Face2face Scheduler Card
.scheduler-details li{
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
    i{
        position: absolute;
        left: 0;
        top: 3px;
    }
}

// Event Progress Bar
.add-event-steps{
    margin-top: 30px;
    position: relative;
    .steps-line{
        height: 100%;
        position: absolute;
        background: $gray;
        width: 2px;
        left: 10px;
    }
    li{
        margin-bottom: 25px;
        padding-left: 45px;
        position: relative;
        color: #254062;
        font-size: 15px;
        span{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 24px;
            height: 24px;
            display: block;
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid #ccc;
            font-size: 14px;
            color: #fff;
            padding: 2px;
        }
        &.active
        {
            color: $regular;
            span{
                border: none;
                background-color: $gray;
                color: $regular;
            }
        }
        &.done{
            color: $done;
            span:before{
                content: "\f00c";
                font-weight: 900;
                font-size: 15px;
                font-size: 13px;
                font-family: 'Font Awesome 5 Free';
                background: $done;
                position: absolute;
                top: -1px;
                left: -1px;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                padding: 3px;
            }
        }
    }
}

.progress {
    height: 14px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #e1e1e1;
    .progress-bar{
        background-image: $success-dark;
        background-color: transparent;
    }
}

.button-view-profile{
    background-color: $default;
    color: $primary;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
}

.button-view-profile:hover{
    background-color: $primary;
    color: #fff !important;
}

.profile-image {
    border-radius: 50%;
    background-color: #f2f2f2;
    background-repeat: no-repeat;
    background-size: contain;
}

.general-container{
    margin-top: 85px;
    margin-right: 40px;
    margin-left: 40px;
}

.social-media-icon{
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    &.fb{
       background-color: #3b5998;
    }
    &.ln{
        background-color: #0077b5;
    }
    &.tw{
        background-color: #1da1f2;
    }
    &.wa{
        background-color: #075e54;
    }
    &.wc{
        background-color: #1aad19;
    }
    &.kt{
        background-color: #fcd411;
        a{
            color: #3c1e1e !important;
        }
    }
    &.sk{
      background-color: #00aff0;
  }
    a{
        color: #fff !important;
        margin: auto;
    }
}

.d-flex-center{
    display: flex;
    align-items: center;
    &.j-center{
        justify-content: center;
    }
}

.card{
    border-radius: 8px;
}

.card.bg-default.c-pointer {
    background-color: #e5eeff;
}

.card.bg-default.c-pointer:hover {
    background-color: #fff;
}

.card.bg-default {
    background-color: #e5eeff;
}

.card.bg-default:hover {
    background-color: #fff;
}

.box-shadow{
    box-shadow: 0px 0px 10px rgba(27, 27, 27, 0.2);
}

.filter-container{
    background: $white;
    display: flex;
    padding: 0px 15px;
    border-radius: 5px;
    position: relative;

    .filter-label{
        align-items: center;
        display: flex;
        margin: 0;
        min-width: 65px;
    }
    .filter-select{
        border: 0;
        background: transparent;
        padding: 0;
        color: $success !important;
        text-transform: capitalize;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    }
}

.filter-container:after {
    content: "\f0dd";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    right: 20px;
    top: 7px;
    pointer-events: none;
}

.modal-content{
    max-width: 100%;
    margin: auto;
}

.c-pointer{
    outline: none !important;
}

button{
    outline: none !important;
}

.min-width-100{
    min-width: 100% !important;
}

.d-flex-end{
    display: flex;
    justify-content: flex-end;
}

._3dots,
.ellipsis,
.overflow-ellipsis{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.button-action-container{
    background-color: #eeeeee;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.password-form-control{
    display: flex;
    align-items: center;
    border-radius: 5px;
    /* border: 1px solid #cccccc; */
    background-color: #f2f2f2;
    &.is-invalid{
        border: 1px solid $danger;
    }
    &.is-valid{
        border: 1px solid $done;
    }
}

.pos-r{
    position: relative !important;
}
.pos-a{
    position: absolute !important;
}
.pos-f{
    position: fixed !important;
}
  
.c-pointer{
    cursor: pointer;
}

.h-auto{
    height: auto !important;
}

.w-auto{
    width: auto !important;
}

.modal-setting-delegate {
	min-width: 511px;
	max-width: 511px;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px; 
    }

	.modal-content {
		max-width: 100%;
	}

	.available-delegates {
		height: 340px;
		overflow-x: hidden;
		overflow-y: auto;

		.cont-height{
			height: 340px;
		}

		.delegates-list{
			padding: 15px 0px;
			border-bottom: solid 1px #ccc;
			cursor: pointer;

			.delegates-photo {
				width: 48px;
				height: 48px;
				float: left;
				pointer-events: none;

				img {
					@include r-all(44px);
					width: 48px;
					height: 48px;
				}
			}

			.delegates-info {
				float: left;
				pointer-events: none;
                text-align: left;

				.company-flag {
					width: 15px;
					height: 15px;
					background-color: #e1e1e1;
					display: block;
					border-radius: 50%;
					margin-right: 8px;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
				}
			}
		}

		.delegates-list.active {
			background: #e8edf6;
		}
	}
}

.zoom-settings{
    &.modal-dialog{
      max-width: 540px !important;
      .modal-content{
        width: 540px;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #EAEDF0;
        border-radius: 5px;
      }
    }
  }