/* You can add global styles to this file, and also import other style files */
@import url('~bootstrap/dist/css/bootstrap');
@import "./styles/mixin";
@import "./styles/color";
@import "./styles/image-icon";

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nEldWYwWb-6bB_.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nEldWYwWD-6bB_.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nEldWYwWz-6bB_.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nEldWYwWL-6Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik Italic'), local('Rubik-Italic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWEBXyIfDnIV7nEnXq61F3f.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik Italic'), local('Rubik-Italic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWEBXyIfDnIV7nEnXy61F3f.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik Italic'), local('Rubik-Italic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWEBXyIfDnIV7nEnXC61F3f.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik Italic'), local('Rubik-Italic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWEBXyIfDnIV7nEnX661A.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nElY2ZwWb-6bB_.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nElY2ZwWD-6bB_.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nElY2ZwWz-6bB_.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nElY2ZwWL-6Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nElcWfwWb-6bB_.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nElcWfwWD-6bB_.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nElcWfwWz-6bB_.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url(https://fonts.gstatic.com/s/rubik/v8/iJWBBXyIfDnIV7nElcWfwWL-6Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Rubik Light'), local('Rubik-Light'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7Fqj2mZ8WDm7Q.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Rubik Light'), local('Rubik-Light'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7Fqj2mf8WDm7Q.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Rubik Light'), local('Rubik-Light'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7Fqj2mT8WDm7Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Rubik Light'), local('Rubik-Light'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7Fqj2md8WA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik'), local('Rubik-Regular'), url(https://fonts.gstatic.com/s/rubik/v8/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik'), local('Rubik-Regular'), url(https://fonts.gstatic.com/s/rubik/v8/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik'), local('Rubik-Regular'), url(https://fonts.gstatic.com/s/rubik/v8/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik'), local('Rubik-Regular'), url(https://fonts.gstatic.com/s/rubik/v8/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Rubik Medium'), local('Rubik-Medium'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7EyjmmZ8WDm7Q.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Rubik Medium'), local('Rubik-Medium'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7Eyjmmf8WDm7Q.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Rubik Medium'), local('Rubik-Medium'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7EyjmmT8WDm7Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Rubik Medium'), local('Rubik-Medium'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7Eyjmmd8WA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Rubik Bold'), local('Rubik-Bold'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7F6iGmZ8WDm7Q.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Rubik Bold'), local('Rubik-Bold'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7F6iGmf8WDm7Q.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Rubik Bold'), local('Rubik-Bold'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7F6iGmT8WDm7Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Rubik Bold'), local('Rubik-Bold'), url(https://fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7F6iGmd8WA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@import "./../node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.btn:focus, .btn.focus {
  box-shadow: none !important;
}
a {
  outline: 0;
}

/* module css */
@import "./app/defaults/sidebar/sidebar.component";
@import "./app/defaults/header/header.component";
@import "./app/modules/events/event-listing/event-listing.component";
@import "./styles/login";
@import "./styles/ronald";


*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: #ebebeb;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  min-width: 1600px !important; //comment ko muna sir ron nasisira layout
  color: $regular;
  width: 100%;  
}
i.fa {
  font-size: 16px
}
p,a {
  color: $regular;
  font-size: 16px;
  line-height: 1.428571429;
}
ul,
ul > li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.d-block{
  display: block;
}
.d-inline-block{
  display: inline-block;
}
.flex{
  display: flex;
  &.ai-center{
    align-items: center;
  }
  &.ai-start{
    align-items: flex-start;
  }
  &.ai-end{
    align-items: flex-end;
  }
  &.jc-center{
    justify-content: center;
  }
  &.jc-start{
    justify-content: flex-start;
  }
  &.jc-end{
    justify-content: flex-end;
  }
}
/* ==== text ==== */
.text-primary{
  color: $primary !important;
}
.text-secondary{
  color: $secondary !important;
}
.text-success-light{
  color: $success-light !important;
}
.text-success{
  color: $success !important;
}
.text-success-dark{
  color: $success-dark !important;
}
.text-danger{
  color: $danger !important;
}
.text-warning{
  color: $warning !important;
}
.text-regular{
  color: $regular;
}
.text-white{
  color: $white;
}
.text-archive{
  color: $archive-text !important;
}
/* ==== background-color ==== */
.bg-primary{
  background-color: $primary !important;
  &.grad{
    background-image: $primary-grad;
  }
}
.bg-default{
  background-color: $default !important;
}
.bg-primary-dark{
  background-color: $primary-dark !important;
}
.bg-success-light{
  background-color: $success-light !important;
}
.bg-archive{
  background-color: $archive-light !important;
}
.bg-success{
  background-color: $success !important;
  &.grad{
    background-image: $success-grad !important;
  }
}
.bg-secondary{
  background-color: $secondary !important;
  &.grad{
    background-image: $secondary-grad !important;
  }
}
.bg-danger-light{
  background-color: $danger-light !important;
}
.bg-danger{
  background-color: $danger !important;
  &.grad{
    background-image: $danger-grad !important;
  }
}
.bg-warning{
  background-color: $warning !important;
  &.grad{
    background-image: $warning-grad !important;
  }
}

/* ==== buttons ==== */
.btn-sm{
  line-height: 1.5 !important;
}
.btn{
  display: inline-block;
  border: 0px;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  padding: 10px 19px;
  font-weight: 400;
  border-radius: 5px;
  background-color: transparent;
  min-width: 100px;
  line-height: 26px;
  &.btn-primary{
    color: #fff !important;
    background-color: $primary;
    &.grad{
      background-image: $primary-grad;
    }
    &.dark{
      background-image: $primary-dark;
    }
  }
  &.btn-danger-light{
    background-color: $danger-light !important;
  }
  &.btn-danger{
    color: #fff !important;
    background-color: $danger;
    &.grad{
      background-image: $danger-grad;
    }
  }
  &.btn-success{
    color: #fff !important;
    background-color: $success;
    &.grad{
      background-image: $success-grad;
    }
  }
  &.btn-archive{
    color: #333333 !important;
    background-color: $gray;
  }
  &.btn-secondary{
    color: #fff !important;
    background-color: $secondary;
  }
  &.btn-default{
    color: $regular !important;
    background-color: $default;
  }
  &.btn-disabled{
    background-color: #cccccc !important;
    background-image: none;
    color: #888;
  }
  &.circle{
    border-radius: 50%;
    width: 34px;
    padding: 8px;
    height: 34px;
  }
  &.shadow{
    box-shadow: 0px 5px 10px rgba(27, 27, 27, 0.3);
  }
}
/* modal */
.modal{
  .modal_sm{
    width: 390px;
  }
  .modal_md{
    max-width: 650px;
  }
}


/* custom dropdown */
.custom-dropdown{
  width: 100%;
  height: 46px;
  .dropdown-toggle{
      border: 1px solid #ccc;
      border-radius: 5px;
      text-align: left;
      width: 100%;
      height: 100%;
      &::after{
          position: absolute;
          right: 15px;
          top: 50%;
          color: $primary;
          font-size: 17px;
      }
      &.invalid{
        border-color: #dc3545;
      }
  }
  .dropdown-menu{
      width: 100%;
      margin: 0px;
      padding: 0;
      border: 0;
      box-shadow: 0px 8px 10px rgba(27, 27, 27, 0.2);
      margin-top: 2px;
      max-height: 205px;
      overflow-y: auto;
      overflow-x: hidden !important;
      li{
          padding: 13px 20px;
          border-bottom: 1px solid #e3e3e3;
          cursor: pointer;
          &.active{
              background-color: $success-light;
              position: relative;
              &::after{
                  position: absolute;
                  content: '\f058';
                  font-family: 'Font Awesome 5 Free';
                  font-weight: 900;
                  right: 15px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: $success;
              }
          }
      }
  }
}



/* ==== badge ==== */
.badge{
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  border-radius: 16px;
  padding: 7px 0px;
  width: 90px;
  &.badge-vip{
    background-image: $vip-table;    
  }
  &.badge-success{
    background-image: $success;    
  }
  &.badge-booth{
    color: #fff;
    background-image: $booth-table;
  }
  &.badge-regular{
    color: #fff;
    background-image: $regular-table;
    &.empty{
      border: 2px solid #ccc;
      background-image: none;
      color: $secondary;
    }
    &.not-empty{
      background-image: $success-grad;
      color: #fff;
    }
  }
}
.badge-inside-event{
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  border-radius: 16px;
  padding: 3px 13px;
  &.badge-vip{
    background-image: $vip-table;    
  }
  &.badge-success{
    background-image: $success;    
  }
  &.badge-booth{
    color: #fff;
    background-image: $booth-table;
  }
  &.badge-regular{
    color: #fff;
    background-image: $regular-table;
    &.empty{
      border: 2px solid #ccc;
      background-image: none;
      color: $secondary;
    }
    &.not-empty{
      background-image: $secondary-grad-light;
      color: $secondary;
    }
  }
}
.badge-sm{
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
  border-radius: 16px;
  padding: 0px 12px;
  &.badge-primary-dark{
    background-image: $primary-dark;
    color: #fff;
  }
}

/* cards */
.card{
  border: 0!important;
  /* box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15); */
  box-shadow: 0px 5px 10px rgba(27,27,27,0.2);
  margin-bottom: 35px;
  .card-footer, 
  .card-header{
    padding: 25px;
    background-color: transparent;
    span{
      font-size: 14px;
      line-height: 24px;
      color: #666666;
      font-weight: 400;
      margin-bottom: 10px;
    }
    h4{
      font-size: 18px;
      line-height: 24px;
      color: #05172e;
      font-weight: 500;
      margin: 0px;
    }
  }
  .card-footer{
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
}
  
/* forms */

.form-control {
  border: 1px solid #cccccc;
  /* background-color: #f2f2f2; */
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  font-family: "Rubik";
  font-weight: 400;
  height: 47px;
  box-shadow: none !important;
}
.form-control{
  &:focus {
    color: #05172e;
    background-color: #fff;
    border-color: #1e50a6 !important;
    outline: 0;
    box-shadow: none !important;
    border: 1px solid #1e50a6 !important;
  }
  &:disabled{
    background-color: #ccc !important;
  }
}
.form-check {
  padding-left: 0px;

  input[type=checkbox] {
    display: none;
  }
  input[type=checkbox] + label {
    margin-left: 0px;
    position: relative;
    padding-left: 35px;
    font-size: 16px;
    line-height: 22px;
  }
  input[type=checkbox] + label:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    @include r-all(3px);
    left: 0;
    border: 1px solid #cccccc;
    background-color: #f2f2f2;
  }

  input[type=checkbox]:checked + label:before {
    background-image: linear-gradient(0deg, #688b06 0%, #88af1b 100%);
    border: 0;
  }
  input[type=checkbox]:checked + label:after {
    position: absolute;
    left: 4px;
    top: 0px;
    color: #fff;
    font-size: 12px;
    content: "\f00c";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
}
.input-group{
  input{
    border-radius: 5px !important;
  }
  position: relative;
  .input-group-prepend{
    position: absolute;
    right: 0;
    border: 0;
    height: 100%;
    cursor: pointer;
    .input-group-text{
      background-color: transparent;
      border: 0;
    }
  }
}


.form-radio {
  padding: 0px;
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      font-size: 16px;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;
      background-color: #f2f2f2;
      border: 1px solid #cccccc;
      border-radius: 100%;
  }
  [type="radio"]:checked + label:before{
    border: 1px solid $success;
  }
  
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: $success;
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type="radio"]:disabled + label:after{
    background: #cecece !important;
  }
  [type="radio"]:disabled + label:before {
    border: 1px solid #f0f0f0 !important;
  }

  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}


.form-legend{
  margin-top: 30px;
  margin-bottom: 15px;
  h6{
    font-size: 18px;
    line-height: 24px;
    color: #05172e;
    font-weight: 500;
    margin-bottom: 1px;
  }
  span{
    font-size: 15px;
    line-height: 24px;
    color: $secondary;
    font-weight: 400;
    
  }
}
.form-group{
  margin-bottom: 21px;
  label{
    display: inline-block;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 24px;
    color: #05172e;
    font-family: "Rubik";
    font-weight: 400;
    margin-right: 15px;
    span.required{
      color: #ff3333;
    }
  }
  input{
    border-radius: 5px;
    border: 1px solid #cccccc !important;
  }
  input[value]:not([value=""]){
    background-color: #ffffff;
  }
}

.form-group {
  .inner-group.search {
    position: relative;
    min-width: 377px;
    float: left;

    input.form-control {
      padding-right: 40px;
    }
  }
  .inner-group.search:after {
    content: "\f002";
    font-family: "";
    position: absolute;
    display: block;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    right: 15px;
    top: 10px;
    color: #666666;
  }
}

.inline-input-button {
  input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
.inner-group.in-line {
  float: left;

  label {
    float: left;
    display: block;
    padding: 12px 0px 0px 0px;
    margin: 0;
    height: 46px;
    min-width: 55px;
  }
  select {
    width: 80px;
    float: left;
  }
  input {
    width: 377px;
    float: left;
  }
}



@include loop(8, 30, 1, ".fs-", "", "font-size", "px", "!important");
@include loop(1, 900, 100, ".fw-", "", "font-weight", "", "!important");
@include loop(1, 50, 1, ".lh-", "", "line-height", "px", "!important");
@include loop(1, 500, 1, ".h-", "", "height", "px", "!important");
@include loop(1, 1500, 1, ".w-", "", "width", "px", "!important");
@include loop(1, 100, 1, ".h-", "-per", "height", "%", "!important");
@include loop(0, 100, 1, ".w-", "-per", "width", "%", "");
@include loop(0, 100, 1, ".mw-", "-per", "max-width", "%", "");
@include loop(0, 150, 1, ".mh-", "", "min-height", "px", "!important");

@include loop(0, 100, 1, ".p-", "", "padding", "px", "!important");
@include loop(0, 100, 1, ".pt-", "", "padding-top", "px", "!important");
@include loop(0, 100, 1, ".pr-", "", "padding-right", "px", "!important");
@include loop(0, 100, 1, ".pl-", "", "padding-left", "px", "!important");
@include loop(0, 100, 1, ".pb-", "", "padding-bottom", "px", "!important");

@include loop(0, 100, 1, ".m-", "", "margin", "px", "!important");
@include loop(0, 100, 1, ".mt-", "", "margin-top", "px", "!important");
@include loop(0, 100, 1, ".mr-", "", "margin-right", "px", "!important");
@include loop(0, 100, 1, ".ml-", "", "margin-left", "px", "!important");
@include loop(0, 100, 1, ".mb-", "", "margin-bottom", "px", "!important");

@include loop(-100, 0, 1, ".m-", "", "margin", "px", "!important");
@include loop(-100, 0, 1, ".mt-", "", "margin-top", "px", "!important");
@include loop(-100, 0, 1, ".mr-", "", "margin-right", "px", "!important");
@include loop(-100, 0, 1, ".ml-", "", "margin-left", "px", "!important");
@include loop(-100, 0, 1, ".mb-", "", "margin-bottom", "px", "!important");

@include loop(-100, 0, 1, ".t-", "", "top", "px", "!important");
@include loop(-100, 0, 1, ".b-", "", "bottom", "px", "!important");
@include loop(-100, 0, 1, ".r-", "", "right", "px", "!important");
@include loop(-100, 0, 1, ".l-", "", "left", "px", "!important");

.r-all-5p {@include r-all(5px)}
.r-all-10p {@include r-all(10px)}
.r-all-15p {@include r-all(15px)}
.r-all-20p {@include r-all(20px)}

.r-tl-5p {@include r-tl(5px)}
.r-tl-10p {@include r-tl(10px)}
.r-tl-15p {@include r-tl(15px)}
.r-tl-20p {@include r-tl(20px)}

.r-tr-5p {@include r-tr(5px)}
.r-tr-10p {@include r-tr(10px)}
.r-tr-15p {@include r-tr(15px)}
.r-tr-20p {@include r-tr(20px)}

.r-bl-5p {@include r-bl(5px)}
.r-bl-10p {@include r-bl(10px)}
.r-bl-15p {@include r-bl(15px)}
.r-bl-20p {@include r-bl(20px)}

.r-br-5p {@include r-br(5px)}
.r-br-10p {@include r-br(10px)}
.r-br-15p {@include r-br(15px)}
.r-br-20p {@include r-br(20px)}

.container.in-general-header {
    margin-top: 85px;
}
.container {
    max-width: 1310px;
}

.main-container{
  margin-top: 64px;
  margin-left: 335px;
  padding: 30px;
  transition: all .2s ease-in-out;

  .section-title{
    font-size: 24px !important;
    margin-bottom: 15px;
    font-weight: 500;
  }
}

.dashboard-full-width {
	section.main-container{
		margin-left: 85px;
	}
}

.events-container{
  margin-top: 64px;
  padding: 30px;
}
.media-icon{
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin-bottom: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  &.companies-icon{
    background-image: url('/assets/icons/companies-icon.png')
  }
  &.delegates-icon{
    background-image: url('/assets/icons/delegates-icon.png')
  }
  &.event-managers-icon{
    background-image: url('/assets/icons/event-managers-icon.png')
  }
  &.tables-icon{
    background-image: url('/assets/icons/tables-icon.png')
  }
  &.schedule-meetings-icon{
    background-image: url('/assets/icons/scheduled-meetings-icon.png')
  }
  &.no-show-icon{
    background-image: url('/assets/icons/no-show-icon.png')
  }
  &.meetings-cancel-icon{
    background-image: url('/assets/icons/meetings-cancelled-icon.png')
  }
}

.nav-back-button{
  border-radius: 16px;
  background-color: #cccccc;
  font-size: 16px;
  line-height: 24px;
  color: #666666 !important;
  padding: 4px 15px;
  display: inline-block;
  margin-bottom: 30px;
}

.nav-back-button:hover {
  text-decoration: underline !important;
}

/* dropzone */
.dropzone {
	width: 400px;
	height: 80px;
	border: 1px dashed #ccc;
	border-radius: 5px;
  background-color: #f2f2f2;
	text-align: center;
  position: relative;
  display: table-cell !important;
  vertical-align: middle;
    p{
      font-size: 15px;
      margin-bottom: 0px;
    }
    span.mb-20{
      margin-bottom: 7px;
      display: block;
    }
    &.event-banner{
        width: 520px;
        height: 290px;
        p{
          padding: 0 145px;
        }
    }
    &.event-icon{
        width: 210px;
        height: 210px;
        p{
          padding: 0 15px;
        }
    }
    &.event-floor{
        width: 910px;
        height: 320px;
        p{
          padding: 0 200px;
        }
    }
    &.event-letterhead{
        width: 910px;
        height: 135px;
        p{
          padding: 0 200px;
        }
    }
}
.event-banner{
    display: block; 
    image-cropper,
    .cropped-preview{
        width: 520px;
        height: 290px;
        border: 1px dashed #ccc;
        background-color: #f2f2f2;
    }
}
.event-icon{
    display: block; 
    image-cropper,
    .cropped-preview{
        width: 210px;
        height: 210px;
        border: 1px dashed #ccc;
        background-color: #f2f2f2;
    }
}
.event-letterhead{
    display: block; 
    image-cropper,
    .cropped-preview{
        width: 910px;
        height: 135px;
        border: 1px dashed #ccc;
        background-color: #f2f2f2;
    }
}

.event-floor{
    display: block; 
    image-cropper,
    .cropped-preview{
        width: 910px;
        height: 320px;
        border: 1px dashed #ccc;
        background-color: #f2f2f2;
    }
}
.cropper-container{
    display: none;
    &.is-loaded{
        display:block;
    }
}
.upload-input {
	position: absolute;
	top: 0px;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}


/* custom input file */
.button-wrapper {
  width: 150px;
  text-align: center;
  display: inline-block;
  position: relative;
}
.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: $primary-grad;
  cursor: pointer;
  color: #fff;
  padding: 10px 0;
  font-size:14px;
  border-radius: 5px;
}
#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}



/* search input */
.search-input{
  position: relative;
  input{
    border-radius: 5px !important;
    background-color: #fff;
  } 
    
  .input-group-text{
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    height: 100%;
    cursor: pointer;
    background-color: transparent !important;
    border: none !important;
    color: #999 !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    font-size: 18px;
  }
}

.data-table {
  table {
    width: 100%;
    border: 1px solid #cccccc;

    thead {
      background-color: #e3e3e3;

      th {
        border-bottom: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
        padding: 12px 15px;
        font-size: 16px;
        color: #444444;
        font-weight: 500;
        text-transform: uppercase;
      }
      th:last-child {
        border-right: 0px;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #cccccc;
        td {
          padding: 18px 15px;
          border-right: 1px solid #cccccc;
        }
        td:last-child {
          border-right: 0px;
        }
      }
      tr:last-child {
        border-right: 0px;
      }
    }
  }
}


.act-btn {
  width: 38px;
  height: 38px;
  border-radius: 19px;
  box-shadow: 0px 5px 10px rgba(27,27,27,0.2);
  font-size: 14px;
  border: 0;
}

.act-btn.ab-grad {
  background-image: linear-gradient(0deg, #1e50a6 0%, #0a3b8c 100%);
  color: #fff;
}

.act-btn.ab-default {
  background-color: #e5f1ff;
  color: #fff;
  color: #05172e;
}
.act-btn.ab-success {
  background-color: #688b06;
  color: #fff;
}
.preference-tabs {
  .tab-container > ul > li > a > span,
  .tab-container > ul > li > a {
    color: $success !important;
    font-weight: 400;
  }
  .tab-container > ul > li > a.active > span,
  .tab-container > ul > li > a.active {
    font-weight: 500;
  }
}
.caret-up-down {
  position: relative;
  display: inline-block;
  height: 15px;
  width: 12px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.caret-up-down:before,
.caret-up-down:after {
  position: absolute;
  display: block;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.caret-up-down:before {
  content: "\f0d8";
  top: -3px;
}

.caret-up-down:after {
  content: "\f0d7";
  bottom: -5px;
}

.caret-up-down.active.asc:before {
  color: #688b06 ;
}
.caret-up-down.active.desc:after {
  color: #688b06 ;
}

.card-footer.text-center.bg-default.p-15.c-pointer {
  background-color: #e5eeff;
}

.empty-state-margin {
  margin: 20px auto;
}


.was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: none !important;
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-invalid, .was-validated .form-control:invalid  {
  border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: none !important;
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.modal-content{
  border: 0px !important;
  /* overflow: hidden; */
}
.modal-backdrop.show{
  opacity: 0.7 !important;
}

.timeslot-selection {
    /* height: 526px !important; */
    overflow-x: scroll !important;
}

.business-card {
  min-height: 165px;
  border-width: 1px;
  border-style: dashed;
  border-radius: 4px;
  color: #999999;
  background-color: #f1f1f1;
}

@import "./styles/ron";
@import "./styles/rhaldnie";
@import "./styles/johnrick";