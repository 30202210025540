.no-outline {
	outline: 0;
}
.c-pointer {
	cursor: pointer;
}
.pagination-container {
  	height: 46px;
	position: relative;

	.pagination-inner {
		.inputgroup {
			float: left;
		}
	}
}

.centerH,
.float-center {
	@include centerH()
}

.centerV {
	@include centerV()
}

.centerVH {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.hidden {
	display: none;
}
.show {
	display: block;
}

.page-link {
  border: 0px;
  background-color: transparent;
  color: $primary !important;
  cursor: pointer;
}
.page-link:hover {
  background-color: transparent;
  text-decoration: underline !important;
}
.page-item.active .page-link {
  background-color: transparent;
  color: $primary;
  font-weight: 500;
  cursor: auto;
}

.act-btn.ab-delete-grad {
	background-image: linear-gradient(0deg, #8c0618 0%, #b01b30 100%);
  	color: #fff;
}

.edit-icon-on-hover {
	position: relative;
}
.edit-icon-on-hover:hover:after {
	content: "\f303";
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	right: 0px;
	color: $primary;
	@include centerV()
}

.modal-dialog.country-states {
	max-width: 598px;
}
.modal-body {
    padding: 30px;
}

.progress {
	.progress-bar.light-green-grad {
		background-image: $success-grad;
		background-color: transparent;
	}
}
.profile-cols {
	float: left;
	position: relative;
	height: 80px;
	margin: 0px 15px;

	.profile-photo {
		border-radius: 40px;
		background-color: #f2f2f2;
		border: 2px solid #cccccc;
		position: relative;
	}

	.upload-btn {
		width: 154px;
		height: 80px;
		position: relative;

		.button-input {
			width: 154px;
		}
	}
	.no-chosen-file {
		width: 115px;
		height: 80px;
		position: relative;
	}
	.recommendation {
		width: 251px;
		height: 80px;
		position: relative;
	}

	.profile-photo {
		img.cropped {
		    border-radius: 20px;
		    -webkit-border-radius: 40px;
		    -moz-border-radius: 20px;
		    width: 76px;
		    height: 76px;
		}
	}
}

.search-100-per {
	width: 100%;

	input {
		min-width: calc(100% - 70px);
	}
}
.modal-dialog {
	position: relative;

	button.close {
		position: absolute;
		right: 25px;
		top: 25px;
		z-index: 999;
	}
}

.modal-dialogVH {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0;
}
.modal_sm {
	width: 390px;
	min-height: 401px;
}

.preference-tabs.justify-normal {
	.tab-container {
		.nav-tabs {
		    justify-content: normal !important;
		}
	}
}
.preference-tabs {
	.tab-container {
		.nav-tabs {
		    .nav-item {
		    	margin: 0;
		    }
		    .nav-item {
		    	padding-left: 30px !important;
				padding-right: 30px !important;
		    }
		}
	}
}

/* schedule table */
.schedule-table {
	border: #cfcfcf solid 1px;

	.table-col {
		float: left;
	}
	.table-col:first-child .table-row {
		border-left: 0px;
	}

	.timeslots {
		width: 245px;
	}
	.agendas {
		/* width: calc(100% - 245px); */
		width: 100%;
	}
	.table-row {
		min-height: 55px;
		border-left: #cfcfcf solid 1px;
		/* border-bottom: #cfcfcf solid 1px; */
		border-bottom: 0px;
		padding: 15px 30px;
	}

	.h-table-row {
		background: #e3e3e3;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
	}
	.b-table-row {
		position: relative;
	}

	.agenda-container {
		/* width: calc(100% - 60px); */
		width: 100%;
		/* height: 180px; */
		border-radius: 5px;
		background-color: #ebf2ff;
		/* position: absolute; */
		z-index: 3;
		/* top: 30px; */
	}
}

.add-edit-agenda-form {
	min-width: 760px;
	max-width: 760px;
}

.styled-select {
	position: relative;
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		padding-right: 25px;
	}
}

.styled-select:after {
	content: "\f0dd";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    right: 10px;
	bottom: 14px;
    pointer-events: none;
}

.toggle-switch {
	height: 27px;
	width: 54px;
	
	.switch {
		display: inline-block;
		height: 34px;
		position: relative;
		width: 60px;
	}

	.switch input {
		display:none;
	}

	.slider {
		background-color: #ccc;
		bottom: 0;
		cursor: pointer;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: .4s;
		height: 27px;
		width: 54px;
		box-shadow: 0px 5px 10px rgba(27,27,27,0.2);
	}

	.slider:before {
		background-color: #fff;
		bottom: 4px;
		content: "";
		height: 19px;
		left: 4px;
		position: absolute;
		transition: .4s;
		width: 19px;
	}

	input:checked + .slider {
		background-image: $success-grad-vv;
	}

	input:checked + .slider:before {
		transform: translateX(26px);
	}

	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
}

.modal_event_timeslot {
	min-width: 670px;
	max-width: 670px;
}

.modal-sched-meeting {
	min-width: 511px;
	max-width: 511px;

	.modal-content {
		max-width: 100%;
		min-height: 624px;
		max-height: 624px;
	}

	.available-delegates {
		height: 340px;
		overflow-x: hidden;
		overflow-y: auto;
		margin: 0px -29px;

		.cont-height{
			height: 340px;
		}

		.delegates-list{
			padding: 20px 29px;
			border-bottom: solid 1px #ccc;
			cursor: pointer;

			.delegates-photo {
				width: 48px;
				height: 48px;
				float: left;
				pointer-events: none;

				img {
					@include r-all(44px);
					width: 48px;
					height: 48px;
				}
			}

			.delegates-info {
				float: left;
				pointer-events: none;

				.company-flag {
					width: 15px;
					height: 15px;
					background-color: #e1e1e1;
					display: block;
					border-radius: 50%;
					margin-right: 8px;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
				}
			}
		}
		.delegates-list:first-child{
			border-top: solid 1px #cccccc;
		}

		.delegates-list.active {
			background: #e8edf6;
		}
	}
	button.select-delegates {
		background-color: #cccccc;
		width: 100%;
	}
	button.select-delegates.active {
		background-color: #0f4092;
		width: 100%;
	}
}
body.loading .spinner-loader {
	display: block;
}
.spinner-loader {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.6);
}
.spinner-loader .spinner {
	-webkit-animation-name: spinner;
	-webkit-animation-duration: 2000ms;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;

	-moz-animation-name: spinner;
	-moz-animation-duration: 2000ms;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;

	-ms-animation-name: spinner;
	-ms-animation-duration: 2000ms;
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: linear;

	animation-name: spinner;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
.spinner-loader .spinner {
	font-size: 6px;
	border-top: 1.1em solid rgba(255, 255, 255, 0.2);
	border-top-color: rgba(255, 255, 255, 0.2);
	border-top-color: rgba(255, 255, 255, 0.2);
	border-right: 1.1em solid rgba(255, 255, 255, 0.2);
	border-right-color: rgba(255, 255, 255, 0.2);
	border-right-color: rgba(255, 255, 255, 0.2);
	border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
	border-bottom-color: rgba(255, 255, 255, 0.2);
	border-bottom-color: rgba(255, 255, 255, 0.2);
	border-left: 1.1em solid #ffffff;
	border-left-color: rgb(255, 255, 255);
	border-left-color: rgb(255, 255, 255);
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 2000;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	//border-color: rgb(59, 103, 178) rgb(59, 103, 178) rgb(59, 103, 178) rgb(166, 205, 57);
	border-color: #3b67b2 #3b67b2 #3b67b2 #a6cd39 !important;
} 
.spinner-loader .spinner.out {
	width: 10em;
	height: 10em;
	opacity: 0.2;
}
@-ms-keyframes spinner { 
	from { 
		-ms-transform: rotate(0deg); 
	} to { 
		-ms-transform: rotate(360deg); 
	}
}
@-moz-keyframes spinner { 
	from { 
		-moz-transform: rotate(0deg); 
	} to { 
		-moz-transform: rotate(360deg); 
	}
}
@-webkit-keyframes spinner { 
	from { 
		-webkit-transform: rotate(0deg); 
	} to { 
		-webkit-transform: rotate(360deg); 
	}
}
@keyframes spinner { 
	from { 
		transform: rotate(0deg); 
	} to { 
		transform: rotate(360deg); 
	}
}
