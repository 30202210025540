@import "./../../../styles/color";

#sidebar::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}
#sidebar:-webkit-scrollbar{
	width: 10px;
	background-color: #F5F5F5;
}
#sidebar::-webkit-scrollbar-thumb{
	background-color: #000000;
	border: 2px solid #555555;
}

#sidebar {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 64px;
    height: calc(100% - 64px);
    width: 335px;
    background-color: #ededed;
    text-align: center;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    padding: 20px 0px;
    background-image: url("/assets/images/sidebar_background.png");
    background-position: -60px -65px;
    overflow-y: auto;
    overflow-x: hidden;
    &:hover, &.hovered {
        width: 335px;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        ul{ 
            span{
                display: inline-block !important;
            }
            &.sub-menu{
                display: block !important; 
            }
        }

    }
    &.not-active{
        width: 80px;
        .sub-menu{
            display: none !important; 
        }
        ul a span{
            display: none !important;
        }
        &:hover, &.hovered {
            width: 335px;
            ul a span{
                display: inline-block !important;
            }
        }
    }
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
        display: block;
        margin: 7px 0px;
        font-size: 14px;
        position: relative;
            a.main-nav{
                border-left: 5px solid;
                border-color: transparent;
                display: block;
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                color: #05172e;
                text-align: left;
                height: 52px;
                i {
                display: inline-block;
                /* height: 46px; */
                line-height: 52px;
                text-align: center;
                -webkit-animation-duration: .7s;
                -moz-animation-duration: .7s;
                -o-animation-duration: .7s;
                animation-duration: .7s;
                -webkit-animation-fill-mode: both;
                -moz-animation-fill-mode: both;
                -o-animation-fill-mode: both;
                animation-fill-mode: both;
                margin-left: 25px;
                margin-right: 17px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 0px;
                }
                span {
                    display: block;
                    height: 52px;
                    line-height: 52px;
                    width: 100%;
                    padding-left: 63px;
                }
                &:hover {
                    border-color: #688b06;
                    background-color: #eee;
                    i {
                        -webkit-animation-name: swing;
                        -moz-animation-name: swing;
                        -o-animation-name: swing;
                        animation-name: swing;
                        color: #688b06;
                    }
                    span{
                        color: #688b06;
                    }
                }
            }
            &.active{
                a{
                    border-color: #688b06;
                    &:before{
                        content: "";
                        display: none;
                        position: absolute;
                        width: 100%;
                        height: 52px;
                        background-color: #ccc;
                        opacity: .3;
                    }
                    color: #688b06;
                    font-weight: 500;
                }
                ul{
                   li{
                       a{
                           font-weight: 400;
                       }
                   } 
                }
            }
            ul.sub-menu{
                display: block;
                li{
                    a{
                        &.active{
                            color: #688b06 !important;
                        }
                        &:hover{
                            text-decoration: none;
                            color: #688b06 !important;
                        }
                        &:before{
                            content: "";
                            background-color: transparent!important;
                        }
                    }
                }
            }
        }
    }
}

@-webkit-keyframes swing {
    20% {
      -webkit-transform: rotate3d(0, 0, 1, 15deg);
      transform: rotate3d(0, 0, 1, 15deg);
    }
    40% {
      -webkit-transform: rotate3d(0, 0, 1, -10deg);
      transform: rotate3d(0, 0, 1, -10deg);
    }
    60% {
      -webkit-transform: rotate3d(0, 0, 1, 5deg);
      transform: rotate3d(0, 0, 1, 5deg);
    }
    80% {
      -webkit-transform: rotate3d(0, 0, 1, -5deg);
      transform: rotate3d(0, 0, 1, -5deg);
    }
    100% {
      -webkit-transform: rotate3d(0, 0, 1, 0deg);
      transform: rotate3d(0, 0, 1, 0deg);
    }
    ;
  }
  
  @keyframes swing {
    20% {
      -webkit-transform: rotate3d(0, 0, 1, 15deg);
      -ms-transform: rotate3d(0, 0, 1, 15deg);
      transform: rotate3d(0, 0, 1, 15deg);
    }
    40% {
      -webkit-transform: rotate3d(0, 0, 1, -10deg);
      -ms-transform: rotate3d(0, 0, 1, -10deg);
      transform: rotate3d(0, 0, 1, -10deg);
    }
    60% {
      -webkit-transform: rotate3d(0, 0, 1, 5deg);
      -ms-transform: rotate3d(0, 0, 1, 5deg);
      transform: rotate3d(0, 0, 1, 5deg);
    }
    80% {
      -webkit-transform: rotate3d(0, 0, 1, -5deg);
      -ms-transform: rotate3d(0, 0, 1, -5deg);
      transform: rotate3d(0, 0, 1, -5deg);
    }
    100% {
      -webkit-transform: rotate3d(0, 0, 1, 0deg);
      -ms-transform: rotate3d(0, 0, 1, 0deg);
      transform: rotate3d(0, 0, 1, 0deg);
    }
    ;
  }
  .swing {
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
  }

  .sidebar-past-event{
    display: block;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    text-align: left;
    height: 40px;
    background-image: linear-gradient(90deg, #888888 0%, #666666 100%);

    .archive_in {
        text-transform: capitalize;
    }
  }