.login-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: url('/assets/images/login-bg.jpg');

    .inner-container > div {
        background: #fff;
        border-radius: 5px;
        position: absolute;
    }

    .inner-container {
        position: relative;
        height: 564px;
        width: 764px;
        left: calc(50% - 382px);
        top: calc(50% - 282px);

        .col1 {
            width: 394px;
            height: 564px;
            box-shadow: 0 0 35px rgba(25,25,25,0.2);
            z-index: 1;
        }
        .col2 {
            width: 370px;
            height: 450px;
            border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;
            top: 57px;
            left: 394px;

            .login-logo {
                width: 100%;
                height: 176px;
                background: url('/assets/images/login-logo-bg.jpg');
                border-top-right-radius: 5px;
                position: relative;

                img {
                    top: calc(50% - 27px);
                    position: absolute;
                    left: calc(50% - 100px);
                }
            }
            .login-logo-sep {
                width: 100%;
                height: 10px;
                box-shadow: 0 0 20px rgba(119,119,119,0.2);
                background-image: linear-gradient(0deg, #688b06 0%, #88af1b 100%);
            }
            
            /*ul > li {
                span.icon {
                    float: left;
                }
                span.text {
                    margin-left: 15px;
                }
            }*/
        }
    }
    .inner-container.forgotpassword {
        width: 394px;
        left: calc(50% - 197px);
        top: calc(50% - 212.5px);

        .col1 {
            height: 425px;
        }
    }
}