.p-relative{
  position: relative;
}
.p-absolute{
  position: absolute;
}
.timepicker {
  .timepicker__header {
    background-image: $success-grad !important;
  }

  .clock-face__clock-hand {
    background-image: $success-grad !important;

    &::after {
      background-image: $success-grad !important;
    }

    &::before {
      border-color: $success !important;
    }
  }

  .clock-face__number {
    > span.active {
      background-image: $success-grad !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: $success !important;
  }
}
.tab-container{
  .nav-link{
    font-size: 18px;
    &.active{
      color: $regular ;
    }
  }
}
.tabset{
  position: relative;
  .tabset-button{
    position: absolute;
    right: 30px;
    top: 10px;
  }
}

.modal_add_table{
  max-width: 555px;
}

.delegate-profile .table-delegate-profile {
    height: 35px;
    width: 35px;
    display: block;
    background-color: #e1e1e1;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
}


.meeting-setter-classification{
  display: block;
  &.sender{
      color: #037b6b;
  }
  &.sender::before{
      content: '\f35a';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      margin-right: 7px;
      transform: rotate(270deg);
      display: inline-block;
  }
  &.receiver{
      color: #291487;
  }
  &.receiver::before{
      content: '\f359';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      margin-right: 7px;
      transform: rotate(270deg);
      display: inline-block;
  }
  &.admin{
      color: #237d06;
  }
  &.admin:before{
      content: '\f2bd';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      margin-right: 7px;
  }
}