@import "./../../../../styles/color";
/* event listing */
.event-item{
  padding: 30px;
  border-bottom: 1px solid #e1e1e1;
}
.nav-tabs{
  border-bottom: 1px solid #e1e1e1;
  .nav-item{
    margin-bottom: 0px;
    margin-left: 30px;
    margin-right: 25px;
    &.active{
      border-bottom: 4px solid $success;
      a{
        color: $default-text-color;
        font-weight: 500;
      }
      .nav-link{
        span{
          opacity: 1;
        }
      }
    }
    .nav-link{
      color: $secondary;
      padding: 18px 0px;
      border-color: transparent !important;
      span{
        opacity: 0.7;
      }
    }
  }
}

.delegate-profile-preference-tabs{
  .tab-container{
      .nav-tabs{
          justify-content: space-between !important;
          .nav-item{
            margin: 0 !important;
            padding: 10px 13px !important;
            a{
              span{
                font-size: 16px !important;
                color: #fff;
              }
            }
            active{
              a{
                span{
                  color: #000 !important;
                }
              }
            }
            .nav-link{
              padding: 10px 0px;
            }
            .nav-link.active{
              background: transparent;
            }
          }
          .nav-item.active{
            border-bottom: 3px solid #a6ce39;
          }
      }
  }
}

.preference-tabs .tab-container > ul > li > a > span:hover, .preference-tabs .tab-container > ul > li > a:hover {
    color: #688b06 !important;
    font-weight: 400;
    opacity: 1
}
.delegate-profile-preference-tabs .tab-container .nav-tabs .nav-item{
  padding: 0px !important;
}

.delegate-profile-preference-tabs .tab-container .nav-tabs .nav-item .nav-link{
  padding: 10px !important;
}

.delegate-profile-event-listing .tab-container .nav-tabs .nav-item{
  margin: 0px !important;
}

.delegate-profile-event-listing .tab-container .nav-tabs .nav-item .nav-link{
  padding: 20px 30px !important;
}

.edit-delegate{
  .nav-tabs .nav-item{
    margin-left: 20px !important; 
    margin-right: 20px !important; 
  }
  .nav-tabs .nav-item:first-child{
    margin-left: 30px !important; 
  }
}

.add_menu{
  position: absolute;
  top: 49px;
  background-color: white;
  width: 192px;
  z-index: 100;
  // right: 72%;
  box-shadow: 0px 2px 11px rgba(1, 1, 1, 0.2);
  .divider{
    border-bottom: 1px grey;
  }
  div{
    cursor: pointer;
    &:hover{
      background-color: #f7f7f7;
    }
  }
}