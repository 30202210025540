
@import "./../../../styles/color";
.header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 64px;
    line-height: 64px;
    color: $default-text-color;
    box-shadow: 0 0 11px rgba(13,13,13,0.05);
    z-index: 300;
    display: flex;
    .logo {
      text-transform: uppercase;
      letter-spacing: 1px;
      background-image: url("/assets/images/f2f_logo.png");
      display: inline-block;
      height: 64px;
      background-repeat: no-repeat;
      width: 214px;
      position: relative;
      background-position: 25px;
      transition: all .5s ease-in-out;
      left: 0;
      z-index: 200;
    }
    #menu-action {
      display: inline;
      position: relative;
      width: 335px;
      height: 64px;
      line-height: 64px;
      margin-right: 15px;
      color: #fff;
      text-decoration: none;
      text-align: center;
      background-color: #fff;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 1px;
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
      border-right: 1px solid #ddd;
      overflow: hidden;
      a{
        position: absolute;
        left: 0;
        height: 64px;
      }
      .menu-toggle{
          width: 65px;
          position: absolute;
          padding: 19px 21px;
          display: table;
          z-index: 1111;
          right: 0;
          text-align: center;
          background-color: #fff;
          .one, .three{
              height: 2px;
              width: 20px;
              background-color: #333;
              display: block;
              margin: 5px 0px;
          }
          .two{
              height: 2px;
              width: 15px;
              background-color: #333;
              display: block;
              margin: 5px 0px;
          }
      }
      i {
        display: inline-block;
        margin: 0 5px;
      }
      span {
        width: auto;
        display: inline;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
      }
      &:hover {
        background: #FFF;
      }
      &.not-active {
        width: 80px;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        span {
          display: none;
          width: auto;
          -webkit-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
        }
      }
    }
    .right-menu{
      // width: calc(100% - 335px);
      float: right;
      position: absolute;
      right: 0;
    }
    .left-menu{
      // width: calc(100% - 335px);
      float: left;
      min-width: 1000px; 
    }
    .event-title{
      color: #333;
      font-weight: 500;
      padding: 12px 0px;
      p{
        margin: 0px;
        line-height: 40px;
      }
    }
    .main-menu{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .seperator{
        height: 32px;
        border-right: 1px solid #e1e1e1;
        padding: 0;
        margin: 0px 15px;
        display: inline-block;
        width: 1px;
        padding: 0px !important;
      }
      a{
        line-height: 40px;
      }
      .btn{
        line-height: 1.5;
      }
      .header-notif-list{
        max-height: 380px;
        /* max-height: 150px; */
        overflow-y: auto !important;
      }
      .header-event-list,.header-notif-list{
        background-color: white;
        width: 400px;
        position: absolute;
        right: 50%;
        margin: 0px auto;
        border-radius: 5px;
        top: 90px;
        inset: unset;
        transform: translate(-50%, 0%) !important;
        display: none;
        overflow: hidden;
        box-shadow: 0px 2px 11px rgba(1, 1, 1, 0.2);
        &.show{
          display: block;
        }
        li{
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
          line-height: 22px;
          .event-logo{
            width: 40px;
            height: 40px;
            margin-right: 15px;
            border: 1px solid #ccc;
            border-radius: 3px;
            background-position: center;
            background-size: cover;
            display: block;
          }
          .notif-icon{
            width: 33px;
            height: 33px;
            margin-right: 15px;
            border-radius: 3px;
            background-position: center;
            background-size: cover;
            border-radius: 50%;
            display: block;
          }
          &.unread{
            background-color: #eee;
          }
          &:last-child{
            border-bottom: none;
          }
          a:hover{
            text-decoration: none;
          }
        }
      }
    }
    .notification-bell{
      position: relative;
      .badge{
        position: absolute;
        background-color: #b01b30 !important;
        color: #fff;
        width: 14px;
        height: 14px;
        padding: 3px;
        font-size: 11px;
        right: -7px;
        top: -4px;
      }
    }
  }
  
.notification-header {
  border-bottom: 0 !important;
}

.alert-inadequate-table{
  position: fixed;
  z-index: 200;
  left: 0;
  right: 0;
  top: 90px;
  margin: 0 auto;
  width: 950px;
  display: inline-block;
  padding: 17px 20px;
  background-color: #feb3bd !important;
  color: #9d2736;
  box-shadow: 0px 2px 11px rgba(1,1,1,.2);
  span{
      border-bottom: 1px solid;
      font-weight: 500;
      padding: 5px 0px;
  }
  a{
    color: #9d2736;
    &:hover{
      text-decoration: none;
    }
  }
}

.badge1{
  border-radius: 20px;
  background-color: #799E11;
  width: 120%;
  max-width: 100px;
  text-align: center;
  color: white;

  margin: 0px;
  line-height: 40px;
}

.badge2{
  border-radius: 20px;
  background-color: #0F3981;
  width: 100%;
  max-width: 100px;
  text-align: center;
  color: white;

  margin: 0px;
  line-height: 40px;
}