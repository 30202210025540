.input-edit {
    border: none;
}

.input-edit:focus-within {
    border: 2px solid #688b06;
}

.mh-180 {
	min-height: 180px !important;
}

.bg-count {
	color : #688b06 !important;
}

.post-event-summary{
	background-image: url('/assets/images/post_event_summary_bg.png') !important;
	background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 5px;


    .pes-title{
    	line-height: 37px;
		color: #05172e;
		font-family: "Rubik";
		font-weight: 500;
    }

    .pes-body{
    	line-height: 24px;
		color: #05172e;
		font-family: "Rubik";
		font-weight: 400;
    }

    .pes-link-button{
    	width: 100%;
		height: 46px;
		border-radius: 5px;
		box-shadow: 0px 5px 10px rgba(27,27,27,0.2);
		background: linear-gradient(356.86deg, #678B05 4.89%, #87AF1B 86.86%);


		.p-text{
			line-height: 24px;
			color: #ffffff !important;
			font-family: "Rubik";
			font-weight: 400;
		}
    }
}

/*pes = post event summary*/

.c-pes-icon-bg-outer {
    width: 119px;
	height: 119px;
	border-radius: 59px;
	background: rgba(242,242,242,.5);
	position: relative;
	margin: 0 auto;
    text-align: center !important;
}

.c-pes-icon-bg-inner {
    width: 96px;	
	height: 96px;
	border-radius: 48px;
	background: rgba(242,242,242,.6);
	position: absolute;

	top: 10%;
    left: 10%;
    background-image: url('/assets/images/post_event_summary_icon.png') !important;
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;

}

.company-clipboard{
    background-color: transparent;
    outline: none !important;
    border: none;
    color: #717070;
    border-radius: 5px;
}

.company-badge{
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    border-radius: 16px;
    padding: 5px 0px;
    width: 100px;
}

.company-badge-success{
    color: #666;
}